<template>

  <v-sheet
      v-if=" !SM ? ( ( index % 2 === 0 && second ) ||  ( index % 2 !== 0 && first)) : second "
      :color="wsLIGHTCARD"
      class="wsRounded d-flex align-center justify-center pa-8 "
      :height="!SM ? 681 : null"
  >
    <v-img class="wsRoundedHalfTop " :src="require(`@/assets/${image}`)" />
  </v-sheet>

  <div v-else class=" d-flex align-center fill-height"  >

    <div>
      <v-icon large :color="wsATTENTION">{{  icon  }}</v-icon>
      <h3 :style="`color : ${wsDARKER}`" class="mt-3">{{ $t(title) }}</h3>
      <h4 :style="`color : ${wsDARKER} ; ${textHeight ? `min-height : ${textHeight}` : null}`"
          v-html="textFormatted"
          style="max-width: 516px" class="font-weight-regular mt-2" />
    </div>

  </div>
</template>

<script>
export default {
  name: "possibilityCard",
  props : {
    title : {
      type : String
    },
    text : {
      type : String
    },
    image : {
      type : String
    },
    icon : {
      type : String,
      default : 'mdi-cog'
    },
    textHeight : {
      type : String,
      default : null
    },
    imageHeight : {
      type : String,
      default : '550px'
    },
    index : {
      type : Number,
      default : 0
    },
    first : {
      type : Boolean,
      default : false
    },
    second : {
      type : Boolean,
      default : false
    },

  },
  computed : {
    textFormatted() {
      let text = this.$t(this.text)
      if ( text.includes('WeStudy'))  {
        text = text.replace('WeStudy' , `<b style="color : ${this.wsATTENTION} !important">WeStudy</b>`)
      }
      if ( text.includes('Fondy'))  {
        text = text.replace('Fondy' , `<b style="color : ${this.wsATTENTION} !important">Fondy</b>`)
      }

      return text
    }
  }
}
</script>

<style scoped>

</style>